<script setup>
const mark = useCookie('bookmark', {
  default: () => ([])
})
const props = defineProps({
  board: {
    type: Object,
    required: true
  },
  traka: {
    type: Boolean,
    default: false
  },
  snap: {
    type: Boolean,
    default: false
  },
  bookmark: {
    type: Boolean,
    default: false
  },
  ratio: {
    type: String,
    default: '9/16'
  }
})
const loaded = ref(false)

// eslint-disable-next-line no-useless-escape
const to = computed(() => (props.board.permalink || props.board.link)?.replace(/^.*\/\/[^\/]+/, ''))

const useBookmark = (x) => {
  if (mark.value.includes(x)) {
    mark.value = mark.value.filter(m => m !== x)
  }
  else {
    mark.value.push(x)
  }
}
</script>

<template>
  <div :class="[{ board_snap: snap, board_snap_traka: traka }]">
    <NuxtLink
      :to="to"
      :class="['board', { traka }]"
    >
      <vNew
        v-if="board.acf?.new"
        class="board_new"
      />
      <div class="placeholder">
        <!-- <img :src="board?.featured || ''" :alt="board?.name || ''" @load="loaded = true"> -->
        <NuxtImg
          :src="board?.featured || ''"
          :alt="board?.name || ''"
          :modifiers="{ w: 220, h: 392, q: 90 }"
          @load="loaded = true"
        />
        <!-- <vSpinner
          v-if="!loaded"
          :size="46"
          color="rgb(var(--color-rgb) / .12)"
        /> -->
      </div>
      <div :class="['board_content', { bookmark }]">
        <div>
          <div
            class="board_title"
            v-text="board.title.rendered?.replace(board.acf?.sku, '')"
          />
          <div
            v-if="board.acf.sku"
            class="board_sku"
            v-text="board.acf.sku"
          />
        </div>
        <span
          v-if="bookmark && !traka"
          @click.stop.prevent="useBookmark(board.id)"
        >
          <vIcon
            :id="!mark?.includes(board.id) ? 'bookmark' : 'bookmark_fill'"
            :fill="mark?.includes(board.id) ? 'var(--brand-color)' : 'rgb(var(--color-rgb) / .5)'"
          />
        </span>
      </div>
    </NuxtLink>
  </div>
</template>

<style scoped>
.board {
  position: relative;

  &.traka {
    & .board_content {
      &.bookmark {
        grid-template-columns: 1fr;
      }
    }
  }
}

.board_content {
  padding-top: 4px;

  &.bookmark {
    display: grid;
    grid-template-columns: auto 24px;
  }
}

.board_title {
  font-size: 14px;
  font-weight: 600;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase
  }
}

.board_sku {
  width: calc(100% - 1px);
  font-size: 11px;
  color: rgb(var(--color-rgb) / .5);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.board_new {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
}

.placeholder {
  aspect-ratio: v-bind(ratio);
  background-color: rgb(var(--color-rgb) / .06);
  position: relative;
  border-radius: 4px;

  & img {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:deep(.spinner) {
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 23px);
  }

  /* &.traka {
    & img {
      left: 33.3333%;
      width: 33.333%;
    }
  } */
}

@media(max-width: 512px) {
  .board_snap {
    width: 75vw;
    scroll-snap-align: center;
    display: flex;
    flex-direction: column;

    &.board_snap_traka {
      width: 90px;
    }
  }

  .board_sku {
    /* width: 6ch */
  }
}
</style>
